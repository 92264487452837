/**
 * this engine for add the function general config
 * @author Harasya
 */

/* ====================================================================================== */
import { setFav, getFav } from 'plugins/favorite';
import Cookies from 'js-cookie';

/**
 * TrackingGA
 * @author Harasya
 */
window.tracking = {
  GAEvent(category, action, label) {
    const PROPERTY = $.cookie('gaProperty');

    if (typeof PROPERTY != 'undefined') {
      const DEFAULTS = {
        dimension2: $.cookie('gaUserType'),
        dimension4: $.cookie('gaUserId'),
        dimension5: $.cookie('gaCampaignName')
      };

      label = Object.assign({}, DEFAULTS, label || {});

      if (typeof ga != 'undefined') {
        window.ga('create', PROPERTY, 'auto');
        window.ga('set', '&uid', $.cookie('gaUserId'));
        window.ga('send', 'event', category, action, label);
      }
    }
  }
};

const userLogin = async () => {
  const userID = window.user_data ? window.user_data.id : 0;
  if (userID) {
    const designs = Cookies.get('idDesign');
    if (designs) {
      await setFav(designs);
      Cookies.remove('idDesign');
    }
  }
};

const userData = () => {
  localStorage.removeItem('permalink');
  localStorage.setItem('permalink', JSON.stringify(window.permalink));
  localStorage.setItem(
    'currency_selected',
    JSON.stringify(window.currency_selected)
  );
  localStorage.setItem(
    'country_selected',
    JSON.stringify(window.country_selected));
  localStorage.setItem('userData', JSON.stringify(window.user_data));

  if (window.browse) {
    localStorage.setItem('browse', JSON.stringify(window.browse));
  }
};

userData();
userLogin();

document.addEventListener('readystatechange', event => {
  console.log('event readystatechange', event);
});

document.addEventListener('DOMContentLoaded', event => {
  console.log('event DOMContentLoaded', event);
});

window.addEventListener('load', async event => {
  console.log('event load', event);
  getFav();
  if (typeof window.favoriteApp !== 'undefined') {
    window.favoriteApp.setItemsHandler();
  }
});
